import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { newAnalyticsEvent } from 'src/utils/analytics';
import Layout from 'src/components/Layout';
import Loader from '../../../components/Loader';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Routes } from '../../../types/Routes';
import { CodeForm } from './CodeForm';
import EmailForm from './EmailForm';
import style from './index.module.scss';
import BubbleHeading from './bubbleHeading';

export const Login = () => {
    const { isLoading, data } = useAuthenticatedUser();
    const [user, setUser] = useState<CognitoUser>({} as CognitoUser);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (data?.isAuthenticated) {
            if (localStorage.getItem('redirectUrl')) {
                // Track an engineer that logs in for the first time
                const userAttributes = data.authenticatedUser?.attributes;
                const isEngineer = userAttributes && userAttributes['custom:role'] === 'engineer';
                const redirectUrl = `${localStorage.getItem('redirectUrl')}`;
                if (isEngineer) {
                    const jobId = redirectUrl?.match(
                        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
                    );
                    if (jobId) {
                        newAnalyticsEvent({
                            category: 'Costs',
                            action: 'Landed',
                            value: jobId[0],
                            label: jobId[0],
                        });
                    }
                }

                navigate(redirectUrl as Routes);
                localStorage.removeItem('redirectUrl');
            } else {
                navigate(Routes.DASHBOARD);
            }
        }
    }, [navigate, data?.authenticatedUser?.attributes, data?.isAuthenticated]);

    const setCognitoUser = (cognitoUser: CognitoUser) => {
        setUser(cognitoUser);
    };

    const emailInput = (value) => {
        setEmail(value.trim());
    };

    const renderForm = () => {
        if ('Session' in user) {
            return <CodeForm user={user} />;
        }
        return <EmailForm onSuccess={setCognitoUser} onChange={emailInput} email={email} />;
    };

    const renderNewCustomer = () => {
        if ('Session' in user) {
            return null;
        }

        return (
            <div className={style.notCustomerYet}>
                <a href="https://www.hometree.co.uk/products/boiler-cover-insurance/">
                    Not a customer yet?
                </a>
            </div>
        );
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Layout>
            <section className={style.loginSection}>
                <BubbleHeading heading={`Let's find you!`} subheading="Just pop in your details" />
                {renderForm()}
                {renderNewCustomer()}
            </section>
        </Layout>
    );
};
