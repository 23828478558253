import { FormState } from '../../types/Form';
import { Config, QuestionStageValues } from '../../types/Question';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    helper?: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    value: string;
    error: string;
    config: Config;
}

const InputTextQuestion = ({
    name,
    title,
    helper,
    formState: _formState,
    setState,
    value,
    error,
    config,
}: Props) => {
    const onChange = (e) => {
        const currentValue = e.target.value;

        setState({ [name]: currentValue || '' });
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <div className={style.inputTextFormControl}>
            <label htmlFor={`input-text-${name}`} className={style.title}>
                {title}
            </label>
            <span className={style.helper}>{helper}</span>
            <input
                type="text"
                id={`input-text-${name}`}
                className={style.inputText}
                value={value}
                onChange={onChange}
                placeholder={config.placeholder || ''}
            />
            {error && validationError}
        </div>
    );
};

export default InputTextQuestion;
