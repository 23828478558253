import { FormState } from '../../types/Form';
import { Config, QuestionStageValues } from '../../types/Question';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    config: Config;
    checked: string;
    error: string;
    uniqueIdentifier?: string;
}

const RadioQuestion = ({
    name,
    title,
    formState: _formState,
    setState,
    config,
    checked,
    error,
    uniqueIdentifier = '',
}: Props) => {
    const radioOptions = config?.options || [];

    const onChange = (e) => {
        const currentValue = e.target.value;

        setState({ [name]: currentValue });
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <>
            <legend className={style.title}>{title}</legend>

            <div className={style.inputAndError}>
                <div className={style.radioContainer}>
                    {radioOptions.map((option, index) => (
                        <div className={style.radioOption} key={option.value}>
                            <input
                                type="radio"
                                name={name}
                                onChange={onChange}
                                id={`${uniqueIdentifier}-option-${name}-${index}`}
                                data-testid={`option-${name}-${index}`}
                                value={option.value}
                                checked={checked === option.value}
                            />
                            <label htmlFor={`${uniqueIdentifier}-option-${name}-${index}`}>
                                <span>{option.copy || option.value}</span>
                            </label>
                        </div>
                    ))}
                </div>
                {error && validationError}
            </div>
        </>
    );
};

export default RadioQuestion;
