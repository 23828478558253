import { Button } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import { AreaOfIssue } from 'src/types/Job';
import { UnreachableError } from 'src/utils/unreachableError';
import { FormState } from '../../types/Form';
import {
    Option,
    Question,
    QuestionStage,
    QuestionStageValues,
    QuestionType,
} from '../../types/Question';
import BubbleRadioQuestion from '../BubbleRadioQuestion';
import { Calendar, validateCalendarInputs } from '../Calendar';
import CategoriesQuestion from '../CategoriesQuestion';
import Complete from '../Complete';
import DropdownQuestion from '../DropdownQuestion';
import DropdownSearchQuestion from '../DropdownSearchQuestion';
import Failed from '../Failed';

import InputTextQuestion from '../InputTextQuestion';
import InputTextQuestionNew from '../InputTextQuestionNew';
import Payment from '../Payment';
import RadioQuestion from '../RadioQuestion';
import StageLoader from '../StageLoader';
import Text from '../Text';
import TextAreaQuestion from '../TextAreaQuestion';
import styles from './index.module.scss';

interface CurrentQuestionProps {
    currentStage: QuestionStage;
    formState: Partial<FormState>;
    contractId: number;
    onStageSubmit: (newStageState: Partial<FormState>, nextStageHandle: string) => void;
}

const CurrentStage = ({
    currentStage,
    formState,
    onStageSubmit,
    contractId,
}: CurrentQuestionProps) => {
    const [currentStageState, setCurrentStageState] = useState<QuestionStageValues>({});
    const [currentStageErrors, setCurrentStageErrors] = useState<Record<string, string>>({});

    const storeQuestionState = (newState: QuestionStageValues) => {
        Object.keys(newState).forEach((key) => {
            // Clear the error when value changes.
            if (currentStageErrors[key]) {
                setCurrentStageErrors({ ...currentStageErrors, [key]: '' });
            }
        });

        setCurrentStageState({ ...currentStageState, ...newState });
    };

    const scrollToQuestion = (name: string) => {
        const element = document.querySelector(`[data-question-name="${name}"]`);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const validateStage = () => {
        const newCurrentStageErrors: Record<string, string> = {};
        let hasScrolled = false;

        currentStage.questions.forEach((question) => {
            if (question.type === QuestionType.TEXT) return;

            if (question.isRequired === true) {
                const { name, type } = question;

                if (
                    (currentStageState[name] === undefined || currentStageState[name] === '') &&
                    type !== QuestionType.CALENDAR
                ) {
                    newCurrentStageErrors[name] = 'This field is required';

                    if (!hasScrolled) {
                        scrollToQuestion(name);
                        hasScrolled = true;
                    }
                }

                // Validate other dropdown
                if (type === QuestionType.DROPDOWN && currentStageState[name] === 'other') {
                    const other = currentStageState[`${name}Other`];

                    if (other === undefined || other === '') {
                        newCurrentStageErrors[name] = 'Please specify your other option';
                    }

                    if (!hasScrolled) {
                        scrollToQuestion(name);
                        hasScrolled = true;
                    }
                }

                // Validate calendar
                if (type === QuestionType.CALENDAR) {
                    const calendarErrors = validateCalendarInputs(currentStageState);

                    if (Object.keys(calendarErrors).length > 0) {
                        Object.keys(calendarErrors).forEach((eachError) => {
                            newCurrentStageErrors[eachError] = calendarErrors[eachError];

                            if (!hasScrolled) {
                                scrollToQuestion(eachError);
                                hasScrolled = true;
                            }
                        });
                    }
                }
            }
        });

        setCurrentStageErrors(newCurrentStageErrors);

        return newCurrentStageErrors;
    };

    const onOptionClick = (option: Option) => () => {
        const errors = validateStage();
        if (Object.keys(errors).length > 0 && option.copy !== 'Back') {
            return;
        }

        let nextQuestion: string | null = null;

        if (typeof option.nextQuestion === 'string') {
            nextQuestion = option.nextQuestion;
        }

        if (typeof option.nextQuestion === 'function') {
            nextQuestion = option.nextQuestion(currentStageState, formState);
        }

        if (nextQuestion === null) {
            // TODO better error handling
            throw new Error('nextQuestion is null');
        }

        if (
            Object.keys(errors).length === 0 ||
            (option.copy === 'Back' && Object.keys(errors).length > 0)
        ) {
            if (option.copy === 'Back') {
                scrollToTop();
                onStageSubmit({ jobType: AreaOfIssue.APPLIANCES }, nextQuestion);
            } else {
                scrollToTop();
                onStageSubmit(currentStageState, nextQuestion);
            }
        }
    };

    // Render each question based on type
    const renderQuestionComponent = (question: Question) => {
        const { name, title, description, helper, type, contribution, config = {} } = question;

        switch (type) {
            case QuestionType.RADIO:
                return (
                    <RadioQuestion
                        name={name}
                        title={description || ''}
                        config={config}
                        formState={formState}
                        checked={String(currentStageState[name])}
                        setState={storeQuestionState}
                        error={currentStageErrors[name] || ''}
                        uniqueIdentifier={currentStage.handle}
                    />
                );
            case QuestionType.BUBBLERADIO:
                return (
                    <BubbleRadioQuestion
                        name={name}
                        title={title || ''}
                        description={description || ''}
                        config={config}
                        formState={formState}
                        checked={String(currentStageState[name])}
                        setState={storeQuestionState}
                        error={currentStageErrors[name] || ''}
                        uniqueIdentifier={currentStage.handle}
                    />
                );
            case QuestionType.CATEGORIES:
                return (
                    <CategoriesQuestion
                        name={name}
                        title={title || ''}
                        description={description || ''}
                        formState={formState}
                        setState={storeQuestionState}
                        error={currentStageErrors[name] || ''}
                        data={question?.data}
                    />
                );
            case QuestionType.TEXTAREA:
                return (
                    <TextAreaQuestion
                        name={name}
                        title={description || ''}
                        helper={helper}
                        formState={formState}
                        setState={storeQuestionState}
                        value={String(currentStageState[name] || '')}
                        error={currentStageErrors[name] || ''}
                        config={config}
                    />
                );

            case QuestionType.INPUT_TEXT:
                return (
                    <InputTextQuestion
                        name={name}
                        title={description || ''}
                        helper={helper}
                        formState={formState}
                        setState={storeQuestionState}
                        value={String(currentStageState[name] || '')}
                        error={currentStageErrors[name] || ''}
                        config={config}
                    />
                );

            case QuestionType.INPUT_TEXT_NEW:
                return (
                    <InputTextQuestionNew
                        name={name}
                        title={description || ''}
                        helper={helper}
                        formState={formState}
                        setState={storeQuestionState}
                        value={String(currentStageState[name] || '')}
                        error={currentStageErrors[name] || ''}
                        config={config}
                    />
                );

            case QuestionType.DROPDOWN:
                return (
                    <DropdownQuestion
                        name={name}
                        title={description || ''}
                        config={config}
                        formState={formState}
                        selected={String(currentStageState[name] || '')}
                        setState={storeQuestionState}
                        error={currentStageErrors[name] || ''}
                    />
                );

            case QuestionType.DROPDOWN_SEARCH:
                return (
                    <DropdownSearchQuestion
                        name={name}
                        title={title || ''}
                        description={description || ''}
                        formState={formState}
                        setState={storeQuestionState}
                        error={currentStageErrors[name] || ''}
                    />
                );

            case QuestionType.PAYMENT:
                return (
                    <Payment
                        title={description || ''}
                        contribution={contribution || 0}
                        contractId={contractId}
                        onOptionClick={onOptionClick}
                        setState={storeQuestionState}
                        currentStageState={currentStageState}
                        currentStage={currentStage}
                        otherTitle={config.otherTitle}
                    />
                );

            case QuestionType.COMPLETE:
                return <Complete title={description || ''} extraCopy={config.extraCopy} />;

            case QuestionType.FAILED:
                return (
                    <Failed
                        title={description || ''}
                        extraCopy={config.extraCopy}
                        rejectProduct={formState.rejectProduct}
                        otherTitle={config.otherTitle}
                    />
                );

            case QuestionType.CALENDAR:
                return (
                    <Calendar
                        title={description || ''}
                        formState={formState}
                        errors={currentStageErrors || []}
                        setState={storeQuestionState}
                    />
                );

            case QuestionType.TEXT:
                return <Text name={name} title={description || ''} config={config} />;

            case QuestionType.STAGE_LOADER:
                return <StageLoader />;

            default:
                return UnreachableError(type);
        }
    };

    return (
        <div className={styles.container}>
            {currentStage.questions.map((eachQuestion) => {
                return (
                    <div
                        key={`question-${currentStage.handle}-${eachQuestion.name}`}
                        className={styles.questionContainer}
                        data-question-name={eachQuestion.name}
                    >
                        {renderQuestionComponent(eachQuestion)}
                    </div>
                );
            })}

            {/* Hide buttons on payment details due to dup button logic */}
            {!currentStage.config?.hideOptionsButtons && (
                <div className={styles.optionsContainer}>
                    {(currentStage.options || []).map((eachOption) => {
                        return (
                            <Button
                                key={`option-${eachOption.copy}`}
                                type="button"
                                variant={`${eachOption.copy === 'Back' ? 'outlined' : 'primary'}`}
                                isDisabled={
                                    eachOption.isDisabled &&
                                    ['appliancesGroup1', 'appliancesGroup2'].includes(
                                        currentStage.handle
                                    )
                                        ? eachOption.isDisabled(currentStageState)
                                        : false
                                }
                                onClick={onOptionClick(eachOption)}
                            >
                                {eachOption.copy}
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CurrentStage;
