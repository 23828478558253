import { Icon } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import InputDropdown from 'src/components/InputDropdown';
import { useApplianceMakesQuery } from 'src/hooks/useApplianceMakesQuery';
import { FormState } from '../../types/Form';
import { QuestionStageValues } from '../../types/Question';
import { mapIconsToAppliances } from '../../utils/mapIconsToAppliances';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    description?: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    error: string;
}

const DropdownSearchQuestion = ({
    name,
    title,
    description,
    formState,
    setState,
    error,
}: Props) => {
    const [selectedOption, setSelectedOption] = useState({ id: null, name: null });
    const { data } = useApplianceMakesQuery();

    const handleSelection = (option) => {
        setSelectedOption(option);
        setState({ [name]: option.name });
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <>
            {formState.jobType && (
                <div className={style.applianceTitle}>
                    <Icon
                        icon={mapIconsToAppliances(formState.jobType)}
                        size="l"
                        variant="primary"
                    />

                    <legend className={style.title}>{title}</legend>
                </div>
            )}
            <legend className={style.title}>{description}</legend>

            <div className={style.inputAndError}>
                <div className={style.dropdownContainer}>
                    <InputDropdown
                        options={data}
                        selectedOption={selectedOption}
                        setSelectedOption={handleSelection}
                    />
                </div>
                {error && validationError}
            </div>
        </>
    );
};

export default DropdownSearchQuestion;
