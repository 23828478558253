import {
    Appliances,
    BoilerIcon,
    DoorsIcon,
    DrainageIcon,
    ElectricIcon,
    HeatingIcon,
    Icon,
    LocksIcon,
    Other,
    PestsIcon,
    PlumbingIcon,
    WindowsIcon,
} from '@HometreeEngineering/component-library';
import { ReactComponent as Back } from 'src/images/chevron-left.svg';
import { FormState } from '../../types/Form';
import { Config, QuestionStageValues } from '../../types/Question';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    description: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    config: Config;
    checked: string;
    error: string;
    uniqueIdentifier?: string;
}

const BubbleRadioQuestion = ({
    name,
    title,
    description,
    formState: _formState,
    setState,
    config,
    checked,
    error,
    uniqueIdentifier = '',
}: Props) => {
    const radioOptions = config?.options || [];

    const onChange = (e) => {
        const currentValue = e.target.value;

        setState({ [name]: currentValue });
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    const determineButtonIcon = (value: string) => {
        switch (value) {
            case 'boiler':
                return BoilerIcon;
            case 'doors':
                return DoorsIcon;
            case 'drainage':
                return DrainageIcon;
            case 'electric':
                return ElectricIcon;
            case 'heating':
                return HeatingIcon;
            case 'locks':
                return LocksIcon;
            case 'pests':
                return PestsIcon;
            case 'plumbing':
                return PlumbingIcon;
            case 'windows':
                return WindowsIcon;
            case 'appliances':
                return Appliances;
            default:
                return Other;
        }
    };

    return (
        <>
            <legend className={style.title}>{title}</legend>
            <legend className={style.description}>{description}</legend>

            <div className={style.inputAndError}>
                <div className={style.radioContainer}>
                    {radioOptions.map((option, index) => (
                        <div className={style.radioOption} key={option.value}>
                            <input
                                className={style.iconInput}
                                type="radio"
                                name={name}
                                onChange={onChange}
                                id={`${uniqueIdentifier}-option-${name}-${index}`}
                                data-testid={`option-${name}-${index}`}
                                value={option.value}
                                checked={checked === option.value}
                                disabled={
                                    config.enabledOptions &&
                                    !config.enabledOptions.includes(option.value)
                                }
                            />
                            <label
                                className={style.iconLabel}
                                htmlFor={`${uniqueIdentifier}-option-${name}-${index}`}
                            >
                                <span />
                                <Icon
                                    icon={determineButtonIcon(option.value)}
                                    size="l"
                                    onClick={() => {}}
                                    variant={
                                        config.enabledOptions &&
                                        !config.enabledOptions.includes(option.value)
                                            ? 'disabled'
                                            : 'primary'
                                    }
                                />
                            </label>
                            <div className={style.copyLine}>
                                <span className={style.copy}>{option.copy || option.value}</span>
                                <input
                                    type="checkbox"
                                    className={style.descriptionCheckbox}
                                    id={`${uniqueIdentifier}-option-${name}-${index}-description`}
                                />
                                <label
                                    className={style.descriptionLabel}
                                    htmlFor={`${uniqueIdentifier}-option-${name}-${index}-description`}
                                >
                                    <Back />
                                </label>
                                <span className={style.subCopy}>{option.subCopy}</span>
                            </div>
                            <span className={style.borderBottom} />
                        </div>
                    ))}
                </div>
                {error && validationError}
            </div>
        </>
    );
};

export default BubbleRadioQuestion;
