import { Button, DetailsModal, Icon } from '@HometreeEngineering/component-library';
import { useCallback, useEffect, useState } from 'react';
import { Appliance } from 'src/types/Appliances';
import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../../types/Form';
import { QuestionStageValues } from '../../types/Question';
import { mapIconsToAppliances } from '../../utils/mapIconsToAppliances';
import styles from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    description: string;
    formState: Partial<FormState>;
    setState: (state: QuestionStageValues) => void;
    error: string;
    data?: CustomerData;
}

const CategoriesQuestion = ({
    name,
    title,
    description,
    formState: _formState,
    setState,
    error,
    data,
}: Props) => {
    const [radioOptions, setRadioOptions] = useState<{ count: number; type: string }[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [groupedData, setGroupedData] = useState<{ category: string; types: any }[]>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentApplianceData, setCurrentApplianceData] = useState<Appliance[]>([]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const groupByCategory = useCallback((arr) => {
        const map = new Map();

        arr.forEach(({ category, type }) => {
            if (map.has(category)) {
                const currentCat = map.get(category);

                const currentType = currentCat.find((cat) => cat.type === type);
                if (!currentType) {
                    currentCat.push({ type, count: 1 });
                } else {
                    const newTypes = currentCat.filter((cat) => cat.type !== type);
                    map.set(category, [...newTypes, { type, count: currentType.count + 1 }]);
                }
            } else {
                map.set(category, [{ type, count: 1 }]);
            }
        });
        return Array.from(map, ([category, types]) => ({ category, types }));
    }, []);

    const handleCategoryClick = (index: number) => {
        setRadioOptions(groupedData?.[index]?.types);
        setSelectedCategory(index);
    };

    useEffect(() => {
        if (data?.appliances) {
            const grouped = groupByCategory(data.appliances);

            if (selectedCategory === null) {
                setGroupedData(grouped);
                setSelectedCategory(0);
                setRadioOptions(grouped[0].types);
            }
        }
    }, [data?.appliances, selectedCategory, groupByCategory]);

    useEffect(() => {
        if (selectedCategory && groupedData) {
            setRadioOptions(groupedData[selectedCategory].types);
        }
    }, [selectedCategory, groupedData]);

    const onChange = (e) => {
        const currentValue = e.target.value;

        setState({ description: currentValue });
    };

    const handleSelect = (id, type) => {
        setState({ [name]: type, applianceId: id });
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <>
            <legend className={styles.title}>{title}</legend>
            <legend className={styles.description}>{description}</legend>
            {isModalOpen ? (
                <DetailsModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    data={currentApplianceData.map((app) => {
                        return {
                            make: app.make,
                            age: app.age,
                            price: app.pricePerMonthGbx,
                            model: app.modelNumber,
                            retailer: app.purchaseRetailer,
                            id: app.id,
                            type: app.type,
                        };
                    })}
                    title={
                        currentApplianceData && currentApplianceData.length > 1
                            ? 'Select the relevant appliance'
                            : 'Take a moment to check the details'
                    }
                    handleSelect={handleSelect}
                    mapper={mapIconsToAppliances}
                    fields={[
                        { key: 'make', label: 'Make' },
                        { key: 'age', label: 'Age' },
                        { key: 'price', label: 'Price' },
                        { key: 'model', label: 'Model' },
                        { key: 'retailer', label: 'Retailer' },
                    ]}
                    contactInfo="0333 912 4843"
                />
            ) : null}
            <div className={styles.categoryBtns}>
                {groupedData?.map((appliance, index) => (
                    <Button
                        key={appliance.category}
                        onClick={() => handleCategoryClick(index)}
                        variant="secondary"
                        subVariant="change"
                        customStyle={`${styles.capitalised} ${
                            selectedCategory === index ? styles.selected : ''
                        }`}
                    >
                        {appliance.category}
                    </Button>
                ))}
            </div>
            <div className={styles.inputAndError}>
                {radioOptions.map((option) => (
                    <div
                        className={`${styles.radioContainer} ${
                            selectedType ? styles.selected : ''
                        }`}
                        key={option.type + option.count}
                    >
                        <div className={styles.radioOption} key={option.type}>
                            <Icon
                                icon={mapIconsToAppliances(option.type)}
                                size="l"
                                aria-label="appliance icon"
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedType(option.type);
                                    if (data?.appliances) {
                                        setCurrentApplianceData(
                                            data.appliances.filter((a) => {
                                                return a.type === option.type;
                                            })
                                        );
                                    }
                                }}
                                variant="primary"
                                isSelected={selectedType === option.type || false}
                            />
                            <p className={styles.optionName}>
                                {option.type}
                                {option.count > 1 ? <span> ({option.count})</span> : null}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            {error && validationError}
            <div className={styles.descriptionContainer}>
                <p>
                    <strong>Please describe the issue</strong>
                </p>
                <p>Provide as much detail as possible</p>
                <textarea
                    className={styles.descriptionInput}
                    name="description"
                    rows={4}
                    onChange={onChange}
                    placeholder="Please detail when the issue began, where the problem is and if there are any accessibility factors we should be aware of..."
                />
            </div>
        </>
    );
};

export default CategoriesQuestion;
