import { FormEvent, MouseEventHandler, useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getMandatoryEnvVar } from 'src/utils/env';
import { useQueryClient } from 'react-query';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { mixpanelTrack } from 'src/utils/mixpanel';
import { SubmitButton } from '../SubmitButton';
import { ReactComponent as Tick } from '../../../../images/tick-rebrand.svg';
import { ReactComponent as Cross } from '../../../../images/cross-rebrand.svg';
import styles from './index.module.scss';
import { sendAuthenticationRequest } from '../../utils/sendAuthenticationRequest';
import CodeInput from '../CodeInput';

interface Props {
    user: CognitoUser;
}

export const CodeForm = ({ user }: Props) => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorInput, setErrorInput] = useState(false);
    const [resentMessage] = useState(false);
    const [resentErrorMessage] = useState(false);

    const queryClient = useQueryClient();
    const { refetch } = useAuthenticatedUser();

    const authenticate = async () => {
        try {
            await sendAuthenticationRequest(user, code);
            setErrorMessage('');
            queryClient.invalidateQueries({ queryKey: ['user'] });
            await refetch();

            mixpanelTrack('portal-login-code-success');
        } catch (err) {
            mixpanelTrack('portal-login-code-fail');
            setErrorInput(true);
            setErrorMessage(err.message);
        }
    };

    useEffect(() => {
        if (
            getMandatoryEnvVar('REACT_APP_ENVIRONMENT') === 'stage' ||
            getMandatoryEnvVar('REACT_APP_ENVIRONMENT') === 'prod'
        ) {
            const ac = new AbortController();
            const androidOTPAutofill = () => {
                if ('OTPCredential' in window) {
                    navigator.credentials
                        .get({
                            otp: { transport: ['sms'] },
                            signal: ac.signal,
                        } as any)
                        .then((otp) => {
                            const otpCode = (otp as any).code;
                            setCode(otpCode);
                        })
                        .catch((err) => {
                            // eslint-disable-next-line
                            console.error(`Failed to autofill OTP: ${err}`);
                        });
                }
            };

            androidOTPAutofill();

            return () => ac.abort();
        }

        return () => {};
    }, []);

    const resendCode: MouseEventHandler<HTMLButtonElement> = async () => {
        // eslint-disable-next-line no-restricted-globals
        window.location.reload();
    };

    const onFormSubmit = async (e: FormEvent<HTMLElement>) => {
        e.preventDefault();
        authenticate();
    };

    const onValueChange = (value: string) => {
        setCode(value);
        setErrorInput(false);
    };

    const renderErrorMessage = () => {
        if (errorMessage === '') {
            return null;
        }

        return <p className={styles.error}>{errorMessage}</p>;
    };

    return (
        <>
            <span className={styles.instructions}>
                Enter the 6-digit code that we&apos;ve just sent you via text message and email.
            </span>
            <form className={styles.codeForm} onSubmit={onFormSubmit}>
                <CodeInput value={code} onChange={onValueChange} error={errorInput} />
                {errorInput && renderErrorMessage()}
                <SubmitButton />
            </form>
            <div className={styles.resendWrapper}>
                <button type="button" className={styles.resendLink} onClick={resendCode}>
                    <span className={styles.placeholder} />
                    Try again
                    {!resentMessage && !resentErrorMessage ? (
                        <span className={styles.placeholder} />
                    ) : (
                        ''
                    )}
                    {resentMessage ? <Tick className={styles.resendIcon} /> : ''}
                    {resentErrorMessage ? <Cross className={styles.resendIcon} /> : ''}
                </button>
            </div>
            {resentErrorMessage && renderErrorMessage()}
        </>
    );
};
